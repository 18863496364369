html, body {
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif !important;
}

a {
    color: initial !important;
}

.container-fluid p a {
    color: #A9A9A9 !important;
}

a:hover {
    text-decoration: none !important;
}

input::placeholder {
    color: #fff !important;
    font-weight: normal;
    font-size: 14px;
}

.mt-75 {
    margin-top: 75px;
}

.socialIcon {
    font-size: 3em;
    color: #999;
    margin-right: 1.5rem;
    cursor: pointer;
}

.socialIcon:hover {
    color: #e0080a;
}

.form-control:focus {
    box-shadow: none !important;
}

.rightPanelTitle {
    font-weight: bold;
    font-size: 24px;
    color: #252D44;
}

.rightPanelDesc {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #252D44;
    max-height: 150px;
    overflow: hidden;
}

.rightPanelImg {
    width: 46px;
    border-radius: 50%;
}

.cardDimension {
    /*width: 202px;*/
    min-height: 298px;
    background: #F6F7F9;
    border-radius: 6px;
    padding: 23px;
    margin-bottom: 15px;
}

.title {
    font-weight: bold;
    font-size: 50px;
    line-height: 63px;
    letter-spacing: -0.03em;
    text-align: left;
}

.boxTitle {
    font-weight: bold !important;
    font-size: 15px !important;
    margin-top: 13px;
    min-height: 36px;
}

.footer-copywrite{
    color: #999 !important;
    font-size: 14px;
    font-weight: 300;
    text-decoration: none;
}
.footer-href{
    color: #999999 !important;
}

.mt-15{
    margin-top: 15px !important;
}
.collapse-border{
    border: 1px solid #dcdcdc;
    border-top: none;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    position: relative;
    top: -15px;
}

.boxEpisodes {
    color: #9098AE;
    font-size: 16px;
    margin-right: 10px;
    font-weight: bold;
}

.podcastTitle {
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.03em;
    color: #000000;
}

.podcastImg {
    width: 100%;
    height: auto;
    border-radius: 12px;
}

.podcastDesc {
    font-weight: normal;
    font-size: 18px;
    letter-spacing: -0.03em;
    color: #000000;
    cursor: pointer;
}

.popularTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #252D44;
    text-transform: uppercase;
    margin-bottom: 24px;
}

.playButton-position {
    position: absolute;
    top: -85px;
    left: 220px;
    width: 69px;
    z-index: 20;
    cursor: pointer;
}

.wavesPosition{
    position: absolute;
    top: -75px;
    left: 35px;
    height: 44px;
}


.react-autosuggest__suggestions-list{
    position: absolute;
    background-color: #fff;
    left: 0;
    width: 100%;
    z-index: 1;
}

.subscribeBox {
    background: #FDFDFD;
    box-shadow: 0px 6px 14px rgba(75, 75, 75, 0.08);
    border-radius: 12px;
}

.subscribeText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.03em;
    color: #797979;
}

.subscribeBorder {
    border: 1px solid #ADADAD;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 3px;
    width: 50%;
    text-align: center;
    margin: 2px;
    font-size: 14px;
    cursor: pointer;
}

.subscribeBorder img {
    width: 23px;
}

.dotsImage {
    position: relative;
    top: -50px;
}

.bottomPlayer {
    height: 95px;
    background-color: #000;
    width: 100%;
    position: fixed;
    bottom: 0;
    border-radius: 12px 12px 0 0;
}

.topPlayer {
    position: absolute;
    top: -11px;
    right: 0;
    background-color: #000;
    height: 59px;
}
.text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.redPlayerSection {
    width: 66px;
    height: 100%;
    background-color: #DA251E;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 12px;
}

.redPlayerSection img {
    width: 37px;
    cursor: pointer;
}

.topRedPlayerSection {
    width: 66px;
    height: 100%;
    background-color: #DA251E;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topRedPlayerSection img {
    width: 37px;
    cursor: pointer;
}

footer {
    background-color: #F5F5F5;
    margin-top: 100px;
    padding: 32px;
    color: #999;
    min-height: 268px;
}
.mr-41{
    margin-right: 41px;
}
.newBadge{
    width: 60px;
}
.lastPublish{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #9098AE;
}

.blackPlayerSection {
    color: #fff;
    padding: 10px 14px;
    width: calc(100% - 66px);
}

.blackPlayerSection p {
    margin: 0;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.search-dimension{
    width: 100% !important;
}
.topBlackPlayerSection {
    color: #fff;
    padding: 6px 14px;
    width: 380px;
}

.topBlackPlayerSection p {
    margin: 0;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blackPlayerSection p:first-child {
    font-weight: bold;
}

.progressPlayerMobile {
    margin-top: 7px;
    width: 100%;
    height: 22px;
    background-color: #262626;
    position: relative;
}
.seekVisibleDesktop{
    width: 100%;
    height: 22px;
    position: relative;
    top: -22px;
    background: transparent;
}
.seekVisibleMobile{
    width: 100%;
    height: 22px;
    position: relative;
    top: -22px;
    background: transparent;
}

.progressPlayerDesktop {
    margin-top: 8px;
    width: 100%;
    height: 22px;
    background-color: #262626;
    position: relative;
}
.progressPlayerDesktop span:first-child{
    position: absolute;
    left: 8px;
    top: -2px;
}

.progressPlayerDesktop span:nth-child(2){
    position: absolute;
    right: 8px;
    top: -2px;
}

.progressPlayerMobile span:first-child{
    position: absolute;
    left: 8px;
    top: -2px;
}

.progressPlayerMobile span:nth-child(2){
    position: absolute;
    right: 8px;
    top: -2px;
}

.progressPlayerDesktopWhite {
    height: 100%;
    background-color: #5B5B5B;
    width: 0;
}

.progressPlayerMobileWhite {
    height: 100%;
    background-color: #5B5B5B;
    width: 0;
}

.radio-badge{
    position: relative;
    top: -2px;
    left: 10px;
}

.badgePink {
    background-color: #FFE7E7;
    border-radius: 17px !important;
    color: #666666;
    padding: 10px 20px !important;
    font-weight: normal !important;
    font-size: 14px !important;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
}

.badgePink:hover {
    background-color: #f1b0b7;
}

.badgePink-active {
    background-color: #f1b0b7;
}
.autocomplete-img{
    width: 50px;
    margin-right: 15px;
}
.react-autosuggest__suggestions-list{
    list-style: none;
    padding: 15px;
    max-height: 300px;
    overflow-y: scroll;
}
.react-autosuggest__suggestions-list li{
    margin-top: 10px;
    cursor: pointer;
}
.footer-top-style p{
    position: relative;
    top: -8px;
}
.footer-top-style p:first-child{
    position: relative;
    top: -5px;
}
.react-autosuggest__container{
    width: 100%;
}


@media only screen and (max-width: 991px) {
    .mb-100 {
        margin-bottom: 100px
    }

    .cardDimension {
        min-height: 214px;
        padding: 15px;
        width: 100%;
    }
    .search-dimension{
        width: 100% !important;
    }
    .title {
        font-size: 30px;
    }

    .mt-75 {
        margin-top: 37px;
    }

    .boxTitle {
        font-size: 14px !important;
    }

    .boxEpisodes {
        font-size: 12px;
    }

    .fs-badge {
        font-size: 10px !important;
    }

    .podcastImg {
        width: 100%;
    }

    .podcastTitle {
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.03em;
        color: #000000;
    }

    .podcastDesc {
        font-size: 14px;
    }
}


::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}
::-webkit-scrollbar-button {
    width: 0;
    height: 0;
}
::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 8px none #ffffff;
    border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
    background: #e1e1e1;
}
::-webkit-scrollbar-thumb:active {
    background: #e1e1e1;
}
::-webkit-scrollbar-track {
    background: transparent;
    border: 0 none #ffffff;
    border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
    background: transparent;
}
::-webkit-scrollbar-track:active {
    background: #333333;
}
::-webkit-scrollbar-corner {
    background: transparent;
}
